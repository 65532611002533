import { SDiv } from "@machineq/elements";
import { FC } from "react";

import { usePermission } from "../auth";
import { FeaturePageOptions } from "./page.model";
import { debugPage } from "./page.utils";
import { PageLoading } from "./PageLoading";
import { PageUnauthorized } from "./PageUnauthorized";

export function withAuthorization<
  T extends JSX.IntrinsicAttributes = Record<string, unknown>
>(PageComponent: FC<T>, options?: FeaturePageOptions) {
  const permissions = options?.requiredPermissions ?? undefined;

  if (!permissions) return PageComponent;

  const permissionString = permissions.toString();
  debugPage.log(`Requires permissions: "${permissionString}".`);

  const AuthorizedPage: FC<T> = (props) => {
    debugPage.log(`Authorizing...`);
    const { hasAccess, hasUserLoaded } = usePermission({
      requiredPermissions: permissions
    });

    if (!hasUserLoaded) {
      debugPage.log("Loading user...");
      return (
        <SDiv>
          <PageLoading />
        </SDiv>
      );
    }

    if (permissions && !hasAccess) {
      debugPage.log(
        `Unauthorized. Page requires permissions "${permissionString}".`
      );
      return <PageUnauthorized />;
    }

    return <PageComponent {...props} />;
  };

  return AuthorizedPage;
}
