import { useAuth0 } from "@auth0/auth0-react";
import { GetUserByIdApiResponse } from "@machineq/models";
import { useGet } from "@pwa/features/api";

export const useGetCurrentUser = () => {
  const { isAuthenticated } = useAuth0();
  const _ = useGet<GetUserByIdApiResponse>({
    shouldLoad: isAuthenticated,
    root: "/user/current"
  });
  return _;
};
