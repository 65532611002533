import { desktopStyles, mobileStyles } from "@machineq/elements";
import clsx from "clsx";
import { forwardRef } from "react";
import styled from "styled-components";

export type LayoutNavProps = JSX.IntrinsicElements["div"] & {
  hideNav?: boolean;
};

export const SLayoutNav = styled.div`
  width: 100%;
  ${mobileStyles} {
    display: initial;

    &.hide-nav {
      display: none;
    }
  }

  ${desktopStyles} {
    width: initial;
    order: 1;
  }
`;

export const LayoutNav = forwardRef<HTMLDivElement, LayoutNavProps>(
  function LayoutNav({ className, hideNav, children, ...restProps }, ref) {
    return (
      <SLayoutNav
        {...restProps}
        ref={ref}
        className={clsx(className, {
          "hide-nav": hideNav
        })}
      >
        {children}
      </SLayoutNav>
    );
  }
);
