import { desktopStyles } from "@machineq/elements";
import { ReactNode, forwardRef } from "react";
import styled from "styled-components";

export const SDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  ${desktopStyles} {
    flex-direction: row;

    & > * {
      height: 100%;
    }
  }
`;

export const Layout = forwardRef<HTMLDivElement, { children: ReactNode }>(
  function Layout({ children, ...restProps }, ref) {
    return (
      <SDiv {...restProps} ref={ref}>
        {children}
      </SDiv>
    );
  }
);
