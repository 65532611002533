import { UserPermissions } from "@machineq/models";
import { useMemo } from "react";

import { useGetCurrentUser } from "./auth.useGetCurrentUser";

export type UsePermissionParams = {
  /**
   * The children components will only display if the user
   * has all of the permissions listed here.
   */
  requiredPermissions: UserPermissions | undefined;
  /**
   * Changes the logic on how the requiredPermissions prop
   * is processed.
   * - `AND` - all permissions must be met
   * - `OR` - only one permission must be met
   */
  logicalOperator?: "AND" | "OR";
};
export type UsePermissionReturn = {
  hasAccess: boolean;
  hasUserLoaded: boolean;
};
export type UsePermission = (
  params: UsePermissionParams
) => UsePermissionReturn;

export const usePermission: UsePermission = ({
  requiredPermissions,
  logicalOperator = "AND"
}) => {
  const { data: user } = useGetCurrentUser();

  const hasAccess = useMemo(() => {
    if (typeof requiredPermissions === "undefined") {
      return true;
    }
    if (
      typeof requiredPermissions === "string" &&
      requiredPermissions === "super-user"
    ) {
      return user?.role === "mq-admin";
    }
    const arrBool = requiredPermissions.map((permission) => {
      if (user?.group.permissions.includes(permission)) {
        return true;
      }
      return false;
    });

    if (logicalOperator === "AND") {
      return arrBool.every((perm) => !!perm);
    }

    return arrBool.includes(true);
  }, [
    logicalOperator,
    requiredPermissions,
    user?.group.permissions,
    user?.role
  ]);

  return { hasAccess, hasUserLoaded: !!user };
};
