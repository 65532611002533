import { ApiQueryParams } from "@machineq/models";
import useSWR, { useSWRConfig } from "swr";
import useSWRImmutable from "swr/immutable";

import {
  ApiRequestConfig,
  formatApiRequestUrlFromApiRequestConfig
} from "./api.utils";

export type useGetParams<QueryParams> = ApiRequestConfig<QueryParams> & {
  shouldLoad?: boolean;
};

/**
 * A function that takes a ApiRequestConfig and returns
 * another function that takes a promise to mutate the data
 * from the cache and then revalidate it.
 */
export function useMutateCache<R, QP = ApiQueryParams>(
  params: useGetParams<QP> | undefined
) {
  const { mutate } = useSWRConfig();
  if (typeof params === "undefined") return;
  const key = formatApiRequestUrlFromApiRequestConfig(params);
  return (fn: (fnParams: R) => Promise<R>) =>
    mutate(key, fn, { revalidate: true });
}

/**
 * An opinionated abstraction over the useSWR hook to make it
 * more inline with our apis and custom fetching mechanisms.
 * The return type is inferred from the implementation of SWR
 */
export function useGet<R, QP = ApiQueryParams>(
  params: useGetParams<QP> | undefined
) {
  const shouldLoad = params?.shouldLoad ?? typeof params !== undefined;

  const swr = useSWR<R>(
    shouldLoad && params
      ? formatApiRequestUrlFromApiRequestConfig<QP>(params)
      : null
  );
  return swr;
}

/**
 * An opinionated abstraction over the useSWRImmutable hook to make it
 * more inline with our apis and custom fetching mechanisms.
 * The return type is inferred from the implementation of SWR
 */
export function useGetImmutable<R, QP = ApiQueryParams>({
  shouldLoad = true,
  ...restEndpointParams
}: useGetParams<QP>) {
  const swr = useSWRImmutable<R>(
    shouldLoad
      ? formatApiRequestUrlFromApiRequestConfig<QP>(restEndpointParams)
      : null
  );
  return swr;
}
