import { makeRem } from "@machineq/elements";
import { Picture } from "@pwa/components/image";
import React, { FC, memo } from "react";
import styled from "styled-components";

import { PageStandalone } from "./PageStandalone";

const StyledSpan = styled.span`
  color: #fff;
  text-align: center;
  display: block;
  margin-top: ${makeRem(48)};
  font-family: inherit;
`;

export const PageLoading: FC<{ title?: string }> = memo(function PageLoading({
  title = "Loading..."
}) {
  return (
    <PageStandalone>
      <div>
        {/* Picture IS BROKEN  */}
        <Picture
          fileName="mq-wordmark-white"
          alt="machine-q-wordmard"
          align="center"
          width={220}
          height="auto"
        />
        <StyledSpan>{title}</StyledSpan>
      </div>
    </PageStandalone>
  );
});

export const PageLoadingFunc = () => <PageLoading />;
