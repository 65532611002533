import {
  Layout,
  LayoutBody,
  LayoutNav,
  type LayoutNavProps
} from "@pwa/features/layout";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import { FeatureLayoutComponent } from "../page";

export type RootProps = LayoutNavProps & { withoutNavbar?: boolean };

const Navbar = dynamic<Record<string, unknown>>(
  async () => import("@pwa/features/navbar").then((mod) => mod.Navbar),
  { ssr: false }
);

export const RootLayout: FeatureLayoutComponent<RootProps> = ({
  children,
  withoutNavbar,
  hideNav
}) => {
  return (
    <div id="machineQ-root" style={{ backgroundColor: "#fff" }}>
      <Layout>
        <LayoutBody>{children}</LayoutBody>
        {useMemo(() => {
          return (
            !withoutNavbar && (
              <LayoutNav hideNav={hideNav}>
                <Navbar />
              </LayoutNav>
            )
          );
        }, [hideNav, withoutNavbar])}
      </Layout>
    </div>
  );
};

RootLayout.getLayout = (page, props) => (
  <RootLayout {...props}>{page}</RootLayout>
);
