import { desktopStyles } from "@machineq/elements";
import { ReactNode, forwardRef } from "react";
import styled from "styled-components";

const SDiv = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: hidden;

  & > * {
    height: 100%;
  }

  ${desktopStyles} {
    width: initial;
    order: 2;
  }
`;

export const LayoutBody = forwardRef<HTMLDivElement, { children: ReactNode }>(
  function LayoutBody({ children, ...restProps }, ref) {
    return (
      <SDiv {...restProps} ref={ref}>
        {children}
      </SDiv>
    );
  }
);
